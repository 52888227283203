<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g" style="margin-top: 80px">
        <div class="row">
            <div class="card card-body border shadow text-center">
                <h4>
                    سيتم تحويلك لنظام خارجي تابع للتحضير الذكي.
                </h4>
                <div class="col-12 g">
                    <button v-if="!loading" class="btn btn-primary" @click="go()">
                        <i class="fa fa-external-link"></i>
                        الذهاب لنظام الجدول المدرسي 
                    </button>
                    <button v-if="loading" class="btn btn-primary disabled" disabled>
                        جاري التحويل...
                    </button>
                    <br><br>
                    <a href="/">
                    <i class="fa fa-reply"></i> عودة للتحضير الذكي</a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            loading: false,
            teacher: JSON.parse(localStorage.getItem('teacher')),
            user: JSON.parse(localStorage.getItem("user")),
        }
    },
    created(){
        if(!checkPer("table")){
            this.$router.push('/NotPermitted')
            return false;
        }
    },
    methods: {
        go(){
            var g = this;
            g.loading = true;
            $.post(api + '/user/general/table-join', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    window.open(r.response)
                }
            }).fail(function(){
                g.loading = false
                alert('فشل الاتصال بنظام الجدول المدرسي، يرجى التواصل مع الدعم الفني لفحصل المشكلة', 200)
            })
    }
    }
}
</script>

<style>
.item{
    border: solid 2px #fff;
}
.item:hover{
    border: solid 2px #ddd;
    cursor: pointer;
    background: #fafafa;
}
.item img{
    width: 64px;
}
</style>